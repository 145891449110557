export const isMobile = (): boolean => {
  try {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  } catch {
    false;
  }
};

export const isDesktop = (): boolean => {
  return !isMobile();
};
