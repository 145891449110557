<app-header [theme]="toggleClass$ | async" [isFixed]="scrollThreshold$ | async" [landing]="true"
  [requestButtonVisibility]="scrollThreshold$ | async" class="header-animate header-landing"></app-header>
<div #videoContainerElement class="video-container">
  <div class="gradient"></div>
  <video *ngIf="!isUz  && isPlatFormBrowser" preload="metadata" muted autoplay autobuffer playsinline webkit-playsinline loop oncanplay="this.play()"
    onloadedmetadata="this.muted = true; this.play()">
    <source  media="(max-width: 762px)" src="https://cdn.optimo.ge/videos/bg_mobile_video.mp4" type="video/mp4" />
    <source  media="(min-width: 762px)" src="https://cdn.optimo.ge/videos/bg_video.mp4" type="video/mp4" />
    Your browser does not support HTML5 video.
  </video>
  <picture *ngIf="isUz">
    @if (isValidTill) {
      <source type="image/webp"
              srcset="assets/images/do‘konni-avtomatlashtirish-chegirma{{isMobile ? '-mobile' : ''}}.webp" />
      <img src="../../assets/images/do‘konni-avtomatlashtirish-chegirma{{isMobile ? '-mobile' : ''}}.jpg" alt="" />
    } @else {
      <source type="image/webp" srcset='assets/images/do‘konni-avtomatlashtirish-chegirma.webp' />
      <img src="../../assets/images/do‘konni-avtomatlashtirish-chegirma.jpg" alt="" />
    }
  </picture>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2 text-center">
        <div class="font-family-medium text-white font-size-18 font-size-sm-32 mb-40px">
          {{(isUz && isValidTill ? 'HomePage.VideoTitle.FirstLineDiscount' : 'HomePage.VideoTitle.FirstLine') | translate}} <br>
          {{ isUz ? ((isValidTill ? 'HomePage.VideoTitle.SecondLineDiscount_Uz' : 'HomePage.VideoTitle.SecondLine_Uz') | translate ) : ('HomePage.VideoTitle.SecondLine' | translate)}}
        </div>
         <div (click)="openVideo()"
          class="btn btn-request-custom-hover btn-outline-primary py-11 px-32 mb-sm-4 mb-3 d-block d-sm-inline-block watch-video">{{'GENERAL.WatchVideo'
          | translate}}</div>

        <a (click)="onRequest()"
          class="btn btn-request-custom-hover btn-primary py-11 ml-sm-4 px-32 mb-4 d-block d-sm-inline-block">{{'GENERAL.RequestDemo'
          | translate}}</a>
      </div>
    </div>
  </div>
  <div class="icon-down-holder position-absolute bottom-80px left-50pct translate-x-n50pct" (click)="scrollDown()">
    <app-icon icon="down"></app-icon>
  </div>
</div>
<div *ngIf="scrollThreshold$ | async" class="w-100 px-15 request-demo-btn-on-scroll-wrapper border-0 shadow-none">
  <a  (click)="onRequest()"
  class="w-100 btn btn-primary px-3 h-100 font-size-14 d-flex align-items-center">
  <span class="col-3 px-0"></span>
  <span class="col text-center px-0">{{'GENERAL.RequestDemo' | translate}}</span>
  <span class="col-3 px-0 d-flex justify-content-end">
    <app-icon icon="arrow-bold"></app-icon>
  </span>
  </a>
</div>
<div class="landing-body bg-white position-relative" [style.margin-top.px]="containerHeight$ | async">
  <div class="container text-center">
    <app-icon icon="pie-chart" [data]="{ rotate: -90 }" class="d-inline-block mb-4"></app-icon>
    <div class="row">
      <div class="col-lg-6 offset-lg-3 mb-4">
        <div class="font-size-24 text-dark font-family-semibold">
          {{'HomePage.WhatIsOptimoSection.Question' | translate}}
        </div>
      </div>
      <div class="col-md-9 offset-md-1 col-lg-8 offset-lg-2">
        <div class="font-size-14 text-muted line-h-24px">
          {{'HomePage.WhatIsOptimoSection.Answer' | translate}}
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="border-bottom">
      <div class="row">
        <div
          class="col-sm-10 offset-sm-1 offset-md-0 col-md-8 col-lg-7 d-flex justify-content-end mt-sm-40px order-2 order-md-1 mb-64 mb-md-0">
          <div class="flex-shrink-lg-0 text-center">
            <video *ngIf="!isUz" isInViewport (isInViewport)="play($event)" preload="none"  muted autobuffer playsinline webkit-playsinline
              onloadedmetadata="this.muted = true" class="landing-img video-1" >
              <source src="https://cdn.optimo.ge/videos/1.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <picture *ngIf="isUz">
              <img src="../../assets/images/do‘kon-uchun-dastur.png" class="landing-img video-1" alt="" loading='lazy'/>
            </picture>
          </div>
        </div>
        <div class="col-sm-10 offset-sm-1 col-md-4 col-lg-5 offset-md-0 order-first order-md-2 flex-list">
          <div class="pl-lg-4 text-center text-lg-left mb-80 mb-sm-0 mb-md-80 mt-100 mt-md-0 mb-lg-0">
            <h1 class="font-size-18 font-size-sm-24 text-center text-md-left font-family-semibold mb-4 line-h-42px">
              <ng-container *ngIf="!isUz">
                <div class="text-dark">{{'HomePage.WhatIsOptimoSection.HowHelpsOptimosShopProg.FirstLine' | translate}}</div>
                <div class="text-primary">{{'HomePage.WhatIsOptimoSection.HowHelpsOptimosShopProg.SecondLine' | translate}}?</div>
              </ng-container>
              <ng-container *ngIf="isUz">
                <div class="text-dark">{{'HomePage.WhatIsOptimoSection.EasyWithOptimo.FirstLine' | translate}}</div>
                <div class="text-primary">{{'HomePage.WhatIsOptimoSection.EasyWithOptimo.SecondLine' | translate}}</div>
              </ng-container>
            </h1>
            <div class="font-size-14 text-muted text-center text-md-left line-h-28px">
              {{isUz ? ('HomePage.WhatIsOptimoSection.EasyWithOptimo.Description_Uz' | translate) :
                      ('HomePage.WhatIsOptimoSection.EasyWithOptimo.Description' | translate)}}
            </div>
          </div>
        </div>
        <div class="col-sm-10 offset-sm-1 col-md-12 offset-md-0 order-3 mt-lg-40px mb-80">
          <div class="row">
            <div class="col-sm-4 mb-5 mb-md-0 text-center">
              <app-icon icon="info"></app-icon>
              <div class="font-family-semibold text-dark mt-4 mb-12">
                {{'HomePage.WhatIsOptimoSection.OptimoIsInformative.title' | translate}}
              </div>
              <div class="font-size-14 text-muted line-h-21px">
                {{'HomePage.WhatIsOptimoSection.OptimoIsInformative.Description' | translate}}
              </div>
            </div>
            <div class="col-sm-4 mb-5 mb-md-0 text-center">
              <app-icon icon="easy"></app-icon>
              <div class="font-family-semibold text-dark mt-4 mb-12">
                {{'HomePage.WhatIsOptimoSection.OptimoIsIntuitive.title' | translate}}
              </div>
              <div class="font-size-14 text-muted line-h-21px">
                {{'HomePage.WhatIsOptimoSection.OptimoIsIntuitive.Description' | translate}}
              </div>
            </div>
            <div class="col-sm-4 mb-5 mb-md-0 text-center">
              <app-icon icon="available"></app-icon>
              <div class="font-family-semibold text-dark mt-4 mb-12 line-h-24px">
                {{'HomePage.WhatIsOptimoSection.OptimoIsDevelopmental.title' | translate}}
              </div>
              <div class="font-size-14 text-muted line-h-21px">
                {{isUz ?
                ('HomePage.WhatIsOptimoSection.OptimoIsDevelopmental.Description_Uz' | translate) :
                ('HomePage.WhatIsOptimoSection.OptimoIsDevelopmental.Description' | translate)
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container text-center mt-60">
    <app-icon icon="pie-chart" [data]="{
        secondaryColor: '#713AC9',
        primaryColor: '#FFAA31'
      }" class="d-inline-block mb-4"></app-icon>
    <div class="row">
      <div class="col-lg-6 offset-lg-3 mb-4">
        <div class="font-size-18 font-size-sm-24 text-dark font-family-semibold">
          {{'HomePage.ManagerWorkspaceSection.Title' | translate}}
        </div>
      </div>
      <div class="col-md-9 offset-md-1 col-lg-8 offset-lg-2">
        <div class="font-size-14 text-muted line-h-24px">
          {{'HomePage.ManagerWorkspaceSection.Description' | translate}}
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-10">
    <div class="border-bottom">
      <div class="row">
        <div class="col-md-10 col-lg-5 col-xl-6 offset-md-1 offset-lg-0 flex-list">
          <div class="mt-80">
            <div class="font-size-18 font-size-sm-24 text-center text-md-left font-family-semibold mb-4 line-h-42px">
              <div class="text-warning">{{'HomePage.ManagerWorkspaceSection.ManageYourBusiness.FirstLine' | translate}}
              </div>
              <div class="text-dark">{{'HomePage.ManagerWorkspaceSection.ManageYourBusiness.SecondLine' | translate}}
              </div>
            </div>
            <div class="font-size-14 text-muted text-center text-md-left line-h-28px">
              <ul class="pl-3">
                <li>{{'HomePage.ManagerWorkspaceSection.Options.Option1' | translate}}</li>
                <li>{{'HomePage.ManagerWorkspaceSection.Options.Option2' | translate}}</li>
                <li>{{'HomePage.ManagerWorkspaceSection.Options.Option3' | translate}}</li>
                <li>{{'HomePage.ManagerWorkspaceSection.Options.Option4' | translate}}</li>
                <li>{{'HomePage.ManagerWorkspaceSection.Options.Option5' | translate}}</li>
                <li>{{'HomePage.ManagerWorkspaceSection.Options.Option6' | translate}}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-10 col-lg-7 col-xl-6 offset-md-1 offset-lg-0 mb-70 mb-lg-0 text-center">
          <video *ngIf="!isUz" isInViewport (isInViewport)="play($event)" preload="none" muted autobuffer playsinline webkit-playsinline
            onloadedmetadata="this.muted = true" class="landing-img video-2">
            <source src="https://cdn.optimo.ge/videos/2.mp4" type="video/mp4" preload="none" controls/>
            Your browser does not support the video tag.
          </video>
          <picture *ngIf="isUz">
            <img src="../../assets/images/biznesni-boshqarish.png" class="landing-img video-2" alt="" loading='lazy'/>
          </picture>
        </div>
        <div class="col-12 mb-80">
          <div class="row">
            <div class="col-sm-4 mb-5 mb-md-0 text-center">
              <app-icon icon="analytics"></app-icon>
              <div class="font-family-semibold text-dark mt-4 mb-12">
                {{'HomePage.ManagerWorkspaceSection.WillBeConstantlyInformed.Title' | translate}}
              </div>
              <div class="font-size-14 text-muted line-h-21px">
                {{'HomePage.ManagerWorkspaceSection.WillBeConstantlyInformed.Description' | translate}}
              </div>
            </div>
            <div class="col-sm-4 mb-5 mb-md-0 text-center">
              <app-icon icon="control"></app-icon>
              <div class="font-family-semibold text-dark mt-4 mb-12">
                {{'HomePage.ManagerWorkspaceSection.WillHaveIntegrationWithRS.Title' | translate}}
              </div>
              <div class="font-size-14 text-muted line-h-21px">
                {{'HomePage.ManagerWorkspaceSection.WillHaveIntegrationWithRS.Description' | translate}}
              </div>
            </div>
            <div class="col-sm-4 mb-5 mb-sm-0 text-center">
              <app-icon icon="commerce"></app-icon>
              <div class="font-family-semibold text-dark mt-4 mb-12 line-h-24px">
                {{'HomePage.ManagerWorkspaceSection.EasilyReadStatistics.Title' | translate}}
              </div>
              <div class="font-size-14 text-muted line-h-21px">
                {{('HomePage.ManagerWorkspaceSection.EasilyReadStatistics.Description'+(isUz ? '-uz' : '')) | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container text-center mt-60">
    <app-icon icon="pie-chart" [data]="{
        rotate: 90,
        secondaryColor: '#713AC9',
        primaryColor: '#27D3A2'
      }" class="d-inline-block mb-4"></app-icon>
    <div class="row">
      <div class="col-lg-6 offset-lg-3 mb-4">
        <div class="font-size-24 text-dark font-family-semibold">
          {{'HomePage.CashierSection.Title' | translate}}
        </div>
      </div>
      <div class="col-md-9 offset-md-1 col-lg-8 offset-lg-2">
        <div class="font-size-14 text-muted line-h-24px">
          {{'HomePage.CashierSection.Description' | translate}}
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div [ngClass]="showDevices ? 'border-bottom' : ''">
      <div class="row">
        <div
          class="col-sm-10 offset-sm-1 offset-md-0 col-md-8 col-lg-7 d-flex justify-content-end mt-sm-40px order-2 order-md-1 mb-64 mb-md-0">
          <div class="flex-shrink-lg-0 text-center">
            <video *ngIf="!isUz" isInViewport (isInViewport)="play($event)" preload="none" muted autobuffer playsinline webkit-playsinline
              onloadedmetadata="this.muted = true" class="landing-img video-3">
              <source src="https://cdn.optimo.ge/videos/3.mp4" type="video/mp4" preload="none" controls/>
              Your browser does not support the video tag.
            </video>
            <picture *ngIf="isUz">
              <img src="../../assets/images/POS.png" class="landing-img video-3" alt="" loading='lazy'/>
            </picture>
          </div>
        </div>
        <div class="col-sm-10 offset-sm-1 col-md-4 col-lg-5 offset-md-0 order-first order-md-2 flex-list">
          <div class="pl-lg-4 text-center text-lg-left mb-80 mb-sm-0 mb-md-80 mt-80 mt-md-0 mb-lg-0">
            <div class="font-size-18 font-size-sm-24 text-md-left font-family-semibold mb-4 line-h-42px">
              <div class="text-success">{{'HomePage.CashierSection.SaveTimeAndSellEasily.FirstLine' | translate}}</div>
              <div class="text-dark">{{'HomePage.CashierSection.SaveTimeAndSellEasily.SecondLine' | translate}}</div>
            </div>
            <div class="font-size-14 text-muted text-center text-md-left line-h-28px">
              <ul class="pl-3">
                <li>{{'HomePage.CashierSection.SaveTimeAndSellEasily.Options.Option1' | translate}}</li>
                <li>{{'HomePage.CashierSection.SaveTimeAndSellEasily.Options.Option2' | translate}}</li>
                <li>{{'HomePage.CashierSection.SaveTimeAndSellEasily.Options.Option3' | translate}}</li>
                <li>{{'HomePage.CashierSection.SaveTimeAndSellEasily.Options.Option4' | translate}}</li>
                <li>{{'HomePage.CashierSection.SaveTimeAndSellEasily.Options.Option5' | translate}}</li>
                <li>{{'HomePage.CashierSection.SaveTimeAndSellEasily.Options.Option6' | translate}}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-10 offset-sm-1 col-md-12 offset-md-0 order-3 mb-80 mt-40px">
          <div class="row">
            <div class="col-sm-4 mb-5 mb-md-0 text-center">
              <app-icon icon="easy-interface"></app-icon>
              <div class="font-family-semibold text-dark mt-4 mb-12">
                {{'HomePage.CashierSection.EasyInterface.Title' | translate}}
              </div>
              <div class="font-size-14 text-muted line-h-21px">
                {{'HomePage.CashierSection.EasyInterface.Description' | translate}}
              </div>
            </div>
            <div class="col-sm-4 mb-5 mb-md-0 text-center">
              <app-icon icon="support"></app-icon>
              <div class="font-family-semibold text-dark mt-4 mb-12">
                {{'HomePage.CashierSection.IntegratedOrders.Title' | translate}}
              </div>
              <div class="font-size-14 text-muted line-h-21px">
                {{'HomePage.CashierSection.IntegratedOrders.Description' | translate}}
              </div>
            </div>
            <div class="col-sm-4 mb-5 mb-md-0 text-center">
              <app-icon icon="integration"></app-icon>
              <div class="font-family-semibold text-dark mt-4 mb-12 line-h-24px">
                {{'HomePage.CashierSection.EasyPayment.Title' | translate}}
              </div>
              <div class="font-size-14 text-muted line-h-21px">
                {{('HomePage.CashierSection.EasyPayment.Description'+(isUz ? '-uz' : '')) | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container  *ngIf="showDevices">
    <div class="container text-center mt-60">
      <app-icon icon="pie-chart" [data]="{
          rotate: 180,
          secondaryColor: '#3DD7AB',
          primaryColor: '#713AC9'
        }" class="d-inline-block mb-4"></app-icon>
      <div class="row">
        <div class="col-lg-6 offset-lg-3 mb-4">
          <div class="font-size-18 font-size-sm-24 text-dark font-family-semibold">
            {{'HomePage.DevicesSection.Title' | translate}}
          </div>
        </div>
        <div class="col-sm-9 offset-sm-1 col-lg-8 offset-lg-2">
          <div class="font-size-14 text-muted line-h-24px">
            {{'HomePage.DevicesSection.Description' | translate}}
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-10 offset-md-1 col-lg-5 col-xl-5 offset-lg-0 flex-list">
          <div class="pl-0 pl-lg-4 mt-80">
            <h1 class="font-size-18 font-size-sm-24 text-center text-md-left font-family-semibold mb-4 line-h-42px">
              <ng-container *ngIf="isUz">
                <div class="text-info">{{'HomePage.DevicesSection.OptimoDevice.FirstLine' | translate}}</div>
                <div class="text-dark">{{'HomePage.DevicesSection.OptimoDevice.SecondLine' | translate}}</div>
              </ng-container>
              <ng-container *ngIf="!isUz">
                <div class="text-info">{{'HomePage.WhatIsOptimoSection.OptimosCashiersDevice.FirstLine' | translate}}</div>
                <div class="text-dark">{{'HomePage.WhatIsOptimoSection.OptimosCashiersDevice.SecondLine' | translate}}</div>
              </ng-container>
            </h1>
            <div class="font-size-14 text-muted text-center text-md-left line-h-28px">
              {{'HomePage.DevicesSection.OptimoDevice.Description' | translate}}
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-xl-7 my-50 text-center">
          <video isInViewport (isInViewport)="play($event)" preload="none" muted autobuffer playsinline webkit-playsinline
            onloadedmetadata="this.muted = true" class="landing-img video-4">
            <source src="https://cdn.optimo.ge/videos/4.mp4" type="video/mp4" preload="none" controls/>
            Your browser does not support the video tag.
          </video>
        </div>
        <div class="col-sm-4 mb-5 text-center mb-80">
          <app-icon icon="available-dark"></app-icon>
          <div class="font-family-semibold text-dark mt-4 mb-12">
            {{'HomePage.DevicesSection.ModernAndBudget.Title' | translate}}
          </div>
          <div class="font-size-14 text-muted line-h-21px">
            {{'HomePage.DevicesSection.ModernAndBudget.Description' | translate}}
          </div>
        </div>
        <div class="col-sm-4 mb-5 text-center mb-80">
          <app-icon icon="touchscreen"></app-icon>
          <div class="font-family-semibold text-dark mt-4 mb-12">
            {{'HomePage.DevicesSection.Flexible.Title' | translate}}
          </div>
          <div class="font-size-14 text-muted line-h-21px">
            {{'HomePage.DevicesSection.Flexible.Description' | translate}}
          </div>
        </div>
        <div class="col-sm-4 mb-5 text-center mb-80">
          <app-icon icon="compact"></app-icon>
          <div class="font-family-semibold text-dark mt-4 mb-12 line-h-24px">
            {{'HomePage.DevicesSection.Compatible.Title' | translate}}
          </div>
          <div class="font-size-14 text-muted line-h-21px">
            {{'HomePage.DevicesSection.Compatible.Description' | translate}}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="bg-light pt-60 pb-0 py-md-60">
    <div class="container text-center mb-5 mb-lg-60">
      <div class="row">
        <div class="col-md-6 offset-md-3 mb-4">
          <h1 class="font-size-18 font-size-sm-24 font-family-semibold text-dark mb-0">
            <ng-container *ngIf="isUz">
              {{'HomePage.OptimoIsAlreadyInUseSection.Title' | translate}}
            </ng-container>
            <ng-container *ngIf="!isUz">
              {{'HomePage.WhatIsOptimoSection.OptimosShopProgramUsing.FirstLine' | translate}}
            </ng-container>
          </h1>
        </div>
      </div>
    </div>
    <div class="container text-left text-md-center">
      <div class="row">
        <div class="col-sm-6 col-md-3 mb-5 mb-lg-0">
          <div class="mb-32">
            <picture>
              <source *ngIf="!isUz"  type="image/webp" srcset='assets/images/Magaziis-programa.webp'/>
              <img [src]="!isUz ? 'assets/images/Magaziis-programa.jpg' : 'assets/images/image-container/do‘konni-avtomatlashtirish.jpg'" class="w-100" alt="" loading='lazy'/>
            </picture>
          </div>
          <div class="font-family-semibold text-dark mb-4">
            {{'HomePage.OptimoIsAlreadyInUseSection.Markets' | translate}}
          </div>
        </div>
        <div class="col-sm-6 col-md-3 mb-5 mb-lg-0">
          <div class="mb-32">
            <picture>
              <source *ngIf="!isUz" type="image/webp" srcset='assets/images/pos-sistema.webp' />
              <img [srcset]="!isUz ? 'assets/images/pos-sistema.jpg' : 'assets/images/image-container/do‘kon-uchun-dastur.jpg'" class="w-100" alt="" loading='lazy'/>
            </picture>
          </div>
          <div class="font-family-semibold text-dark mb-4">{{'HomePage.OptimoIsAlreadyInUseSection.DrivesBooths' |
            translate}}</div>

        </div>
        <div class="col-sm-6 col-md-3 mb-5 mb-lg-0">
          <div class="mb-32">
            <picture>
              <source *ngIf="!isUz" type="image/webp" srcset='assets/images/salaros-programa.webp' />
              <img [src]="!isUz ? 'assets/images/salaros-programa.jpg' : 'assets/images/image-container/Kassirlar-uchun-dastur.jpg'" class="w-100" alt="" loading='lazy'/>
            </picture>
          </div>
          <div class="font-family-semibold text-dark mb-4">
            {{'HomePage.OptimoIsAlreadyInUseSection.Agro' | translate}}
          </div>

        </div>
        <div class="col-sm-6 col-md-3 mb-5 mb-lg-0">
          <div class="mb-32">
            <picture>
              <source  *ngIf="!isUz" type="image/webp" srcset='assets/images/restornis-programa.webp' />
              <img [src]="!isUz? 'assets/images/restornis-programa.jpg': 'assets/images/image-container/Savdoni-avtomatlashtirish.jpg'" class="w-100" alt="" loading='lazy'/>
            </picture>
          </div>
          <div class="font-family-semibold text-dark mb-4">
            {{'HomePage.OptimoIsAlreadyInUseSection.FastFoot' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pt-80 pb-lg-80 pb-md-65 pb-125">
    <div class="container text-center">
      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <div class="font-size-18 font-size-sm-24 text-dark font-family-semibold mb-4">
            {{'HomePage.BecomeTheNext' | translate}}
          </div>
          <div class="font-size-14 text-muted mb-40px">
            {{'HomePage.ManageAndGrowYourBusinessWithOptimo' | translate}}
          </div>
          <a [routerLink]="'/' + translateService.currentLang+'/request-demo'" (click)="onRequest()"
            class="btn btn-request-custom-hover btn-primary py-12 px-32">
            {{'GENERAL.JoinUs' | translate}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
