import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from '@optimo/core';
import { fromEvent, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  share,
  startWith,
  tap,
} from 'rxjs/operators';
import { Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { VideoDialogComponent } from './video-dialog/video-dialog.component';
import { isUz, isGe } from '../../config/ui-config';
import { SharedModule } from '../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '../icon/icon.module';
import { IsInViewportDirective } from '../shared/directives/is-in-viewport.directive';
import { isMobile } from '../../../../dashboard/src/app/core/helpers/device-detection/device-detector.helper';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  imports: [
    TranslateModule,
    CommonModule,
    SharedModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule,
    IconModule,
    IsInViewportDirective,
  ],
  standalone: true,
})
export class LandingComponent implements OnInit {
  @ViewChild('videoContainerElement', { static: true })
  videoContainer: ElementRef;
  readonly isMobile = isMobile();
  public isUz = isUz;
  public isGe = isGe;
  public isPlatFormBrowser = false;
  private scroll$ = fromEvent(this.document, 'scroll', {
    passive: true,
  }).pipe(share());

  scrollThreshold$: Observable<boolean> = this.scroll$.pipe(
    map(() => {
      const fullHeight = this.videoContainer.nativeElement.clientHeight;
      if (isPlatformBrowser(this.platformId)) {
        const scrollTop = this.document.documentElement.scrollTop;
        return scrollTop > fullHeight / 1.7;
      }
    }),
    tap((val) => {
      if (isPlatformBrowser(this.platformId)) {
        if (val) {
          this.document.body.classList.add('scrolled-from-header');
        } else {
          this.document.body.classList.remove('scrolled-from-header');
        }
      }
    }),
    distinctUntilChanged(),
    startWith(false),
  );

  toggleClass$: Observable<'light' | 'dark'> = this.scrollThreshold$.pipe(
    map((state) => (state ? 'light' : 'dark')),
  );

  containerHeight$ = this.scroll$.pipe(
    map(() => {
      if (isPlatformBrowser(this.platformId)) {
        const scrollTop = this.document.documentElement.scrollTop;
        return -scrollTop / 2;
      }
    }),
    filter((h) => h > -500),
    distinctUntilChanged(),
    startWith(0),
  );

  constructor(
    private matDialog: MatDialog,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: unknown,
    private googleAnalytics: GoogleAnalyticsService,
    public translateService: TranslateService,
    private router: Router,
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) this.isPlatFormBrowser = true;
  }

  scrollDown(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    window.scrollTo({
      top: this.videoContainer.nativeElement.clientHeight - 350,
      behavior: 'smooth',
    });
  }

  play(el: ElementRef): void {
    el.nativeElement.play();
  }

  onRequest(): void {
    this.router.navigate([
      '/' + this.translateService.currentLang + '/request-demo',
    ]);
    this.googleAnalytics.sendEvent('request_order', 'button_click');
    this.router.navigate([
      '/' + this.translateService.currentLang + '/request-demo',
    ]);
  }

  onRequestDemo(): void {
    this.googleAnalytics.sendEvent('request_demo', 'button_click');
  }

  openVideo(): void {
    this.matDialog.open(VideoDialogComponent, {
      panelClass: ['mat-bg-transparent', 'mat-mw-u-xs-95vw'],
    });
  }

  get showDevices() {
    return this.isGe;
  }

  get isValidTill() {
    const today = new Date();
    const cutoffDate = new Date('2024-10-31');
    // If today's date is before or on October 15, show the image
    return today <= cutoffDate;
  }
}
